import React, { useState } from 'react'
import { Container, Alert, Button } from 'reactstrap'
import './headerBar.scss'

const HeaderBar = () => {
  const [visible, setVisible] = useState(true)
  const onDismiss = () => setVisible(false)

  return (
    <Container fluid={true} className="px-0 text-center">
      <div className={visible ? 'topbarContainer' : ''}>
        <div className="topbar">
            <Alert color="danger" isOpen={visible} toggle={onDismiss}>
              <a href="https://balooliving.xayxet.net/JeZb7" target="_blank">
                <span className="mr-3"><strong>Mystery Offer at Baloo Weighted Blanket</strong></span>
                <Button color="primary">Let's see!</Button>
              </a>
              {/* <a href="https://luxome.pxf.io/1rk6Ed" target="_blank">
                <span className="mr-3"><strong>Holiday Sales 40% Off | Luxome Weighted Blanket</strong></span>
                <Button color="primary">Let's see!</Button>
              </a> */}
            </Alert>
        </div>
      </div>
    </Container>
  )
}

export default HeaderBar
