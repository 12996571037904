import React from 'react'
import { Link } from 'gatsby'
import {
  Navbar,
  Collapse,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Container } from 'reactstrap'
import './navigation.scss'
import logo from './weightedblanketguides-logo.png'
export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.menuLinks = props.menuLinks

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  closeNavbar() {
    if (this.state.collapsed !== true) {
      this.toggleNavbar();
    }
  }

  dropdownItems(children) {
    return (
      <DropdownMenu>
        {children.map(c => { return this.dropdownItem(c) })}
      </DropdownMenu>
    )
  }

  dropdownItem(item) {
    return (
      <DropdownItem key={item.text}>
        <Link className="nav-link navigation__navlinks" to={item.link}>{item.text}</Link>
      </DropdownItem>
    )
  }

  render() {
    return (
      <header>
        <Navbar className="navigation__navbar" light expand="lg">
          <Container className="flex-md-column">
            <NavbarBrand href="/">
              <img src={logo} alt="Weighted Blanket Guides" className="logo-image"></img>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mx-auto" aria-label="Weighted Blanket Guides Navbar Toggler"/>
            <Collapse isOpen={!this.state.collapsed} navbar>
              <Nav className="mr-auto" navbar>
                {this.menuLinks
                  .map(( item ) => {
                    if (item.link === "") {
                      return (
                        <UncontrolledDropdown nav inNavbar key={item.text}>
                          <DropdownToggle nav caret key={item.text}>{item.text}</DropdownToggle>
                          {item.children ? this.dropdownItems(item.children) : ''}
                        </UncontrolledDropdown>
                      )
                    } else {
                      return (
                        <NavItem key={item.text} className="navigation__item" style={{ whiteSpace: 'nowrap' }}>
                          <Link className="nav-link navigation__navlinks" to={item.link}>
                            {item.text}
                          </Link>
                        </NavItem>
                      )
                    }
                  })
                }
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}


