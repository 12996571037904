import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Header from '../../components/header/Header'
import HeaderBar from '../../components/headerBar/HeaderBar'
import Footer from '../../components/footer/Footer'

import './bootstrap.min.css'
import './layout.scss'

class Layout extends React.Component {
  render() {
    const { children, layout } = this.props
    let content

    if (layout === "fullwidth") {
      content = children
    } else {
      content = (
        <Row className="text-xs-center">
          <Col className="site-content-primary">
            {children}
          </Col>
        </Row>
      )
    }

    return (
      <div>
        <HeaderBar />
        <Container className="site">
          <Header/>
          <Container className="site-content text-xs-center">
            {content}
          </Container>
        </Container>
        <Footer/>
        <script dangerouslySetInnerHTML={{ __html: `window.dojoRequire(["mojo/signup-forms/Loader"], function(L) { L.start({"baseUrl":"mc.us18.list-manage.com","uuid":"93574be4c13abbfa19f1e2371","lid":"aec5b68a75","uniqueMethods":true}) })`}}></script>
      </div>
    )
  }
}

export default Layout
