import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import './footer.scss'

class Footer extends React.Component {
render() {
  return (
    <Container className="footer__container">
      <Container>
        <Row className="footer__row">
          <Col xs="12" md={{ size: 4 }}>
            <div>
              <span className="footer__widget--title">DISCLAIMER</span>
              <br/><br/>
              <div>I am not a healthcare professional, physician or licensed therapist and do not provide healthcare services, medical treatment, diagnoses or services. The information referenced herein does not constitute an attempt to provide medical advice or practice medicine. If you or your child are under the care of a licensed therapist or doctor, please consult with your therapist and/or doctor before using any of the products mentioned on this site.</div>
              <br/>
            </div>
          </Col>
          <Col xs="12" md={{ size: 4 }}>
            <div>
              <span className="footer__widget--title">AMAZON ASSOCIATES PROGRAM</span>
              <br/><br/>
              <p className="footer__widget-content">Amazon Associates Program WeightedBlanketGuides.com is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com. *Amazon and the Amazon logo are trademarks of Amazon.com, Inc., or its affiliates. Additionally, WeightedBlanketGuides.com participates in various other affiliate programs, and we sometimes get a commission through purchases made through our links.</p>
            </div>
          </Col>
          <Col xs="12" md={{ size: 2, offset: 2}}>
            <div>
              <span className="footer__widget--title">PAGES</span>
              <br/><br/>
              <ul className="footer__ul">
                <li><a href="/contact-us/" className="white--text">Contact Us</a></li>
                <li><a href="/about-us/" className="white--text">About Us</a></li>
                <li><a href="/privacy-policy/" className="white--text">Privacy Policy</a></li>
                <li><a href="/terms-of-use/" className="white--text">Terms of Use</a></li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="footer_row">
          <Col xs="12" md={{ size: 12 }}>
            <div><a href="//www.dmca.com/Protection/Status.aspx?ID=64d865f7-73bf-45ed-a798-b8591bc0ab23" title="DMCA.com Protection Status" className="dmca-badge"> <img src ="https://images.dmca.com/Badges/dmca_protected_sml_120n.png?ID=64d865f7-73bf-45ed-a798-b8591bc0ab23"  alt="DMCA.com Protection Status" /></a>  <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script></div>
            <div className="footer__copyright"></div>Copyright © 2023 WeightedBlanketGuides
          </Col>
        </Row>
      </Container>
    </Container>
    )
  }
}

export default Footer
