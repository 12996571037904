import path from 'path'
import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
// import config from '../config';

const getSchemaOrgJSONLD = ({
  isBlogPost,
  url,
  title,
  image,
  description,
  datePublished,
  dateModified,
  section,
  config
}) => {
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: config.name,
      alternateName: config.title,
    }
  ]

  return isBlogPost
    ? [
        ...schemaOrgJSONLD,
        {
          '@context': 'http://schema.org',
          '@type': 'Blog',
          mainEntityOfPage: {
            '@type': 'WebSite',
            '@id': config.url,
          },
          url,
          name: title,
          alternateName: config.title,
          headline: title,
          image: {
            '@type': 'ImageObject',
            url: image,
          },
          articleSection: section,
          description,
          author: {
            '@type': 'Person',
            name: 'Admin',
          },
          publisher: {
            '@type': 'Organization',
            url: 'https://www.weightedblanketguides.com',
            logo: config.logo,
            name: 'Weighted Blanket Guides',
          },
          // datePublished,
          dateModified
        },
      ]
    : schemaOrgJSONLD
};

const SEO = ({ postData, postImage, isBlogPost, config }) => {
  const postMeta = postData.frontmatter || {}

  const title = postMeta.title || config.title
  const description = postMeta.description
    || postData.fields && postData.fields.metaDescription
    || postData.excerpt
    || config.description
  const image = postImage !== '' ? `${config.url}${postImage}` : config.image || null
  const url = postData && postData.fields && postData.fields.slug ? `${config.url}/${postData.fields.slug}` : config.url
  const datePublished = isBlogPost ? postMeta.datePublished : null
  const dateModified = isBlogPost ? postMeta.dateModified : null
  const section = isBlogPost ? postData.category : null
// console.log('postData', postData)
// console.log('postMeta', postMeta)
  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    isBlogPost,
    url,
    title,
    image,
    description,
    datePublished,
    dateModified,
    section,
    config
  })
// console.log('schemaOrg', schemaOrgJSONLD)

  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      {/* { Preconnect to establish connection to other origins } */}
      <link rel="preconnect" href="https://use.fontawesome.com" crossorigin></link>
      <link rel="preconnect" href="https://www.googletagmanager.com" crossorigin></link>
      <link rel="preconnect" href="https://connect.facebook.net" crossorigin></link>
      <link rel="preconnect" href="https://fonts.googleapis.com" crossorigin></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>

      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      {isBlogPost ? <meta property="og:type" content="article" /> : null}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image ? <meta property="og:image" content={image} /> : null}
      {image ? <meta property="og:image:secure_url" content={image} /> : null}
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Weighted Blanket Guides" />
      {datePublished ? <meta property="article:published_time" content={datePublished} /> : null}
      {dateModified ? <meta property="article:modified_time" content={dateModified} /> : null}
      {section ? <meta property="article:section" content={section} /> : null}
      {/* <meta property="fb:app_id" content={config.fbAppID} /> */}
      {/* <meta property="fb:admins" content="Facebook numeric ID" /> */}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.twitter} />
      <meta name="twitter:site" content={config.siteUrl}/>
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image ? <meta name="twitter:image" content={image} /> : null}

      {/* DMCA badge validation */}
      <meta name='dmca-site-verification' content='d0lDK3grc0RjUEdlSndMMktBUTNpcmxYSm8vdlU0NWcrMEFyNVAyOUw5dz01' />

      {/* Font Awesome imports */}
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css" integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossorigin="anonymous"/>

      {/* Search Console */}
      <meta name="google-site-verification" content="Dr5XD7UvNQ_YigEVdtKyqGwwKnNh87HMSEA7a5PXreo" />

      {/* Mailchimp popup */}
      <script type="text/javascript" src="//downloads.mailchimp.com/js/signup-forms/popup/unique-methods/embed.js" data-dojo-config="usePlainJson: true, isDebug: false"></script>

      {/* Hello Bar */}
      {/* <script src="https://my.hellobar.com/25d22536a4e772aaaf79b5c5b3d810eb1de43ce5.js" type="text/javascript" charset="utf-8" async="async"></script> */}
    </Helmet>
  );
};

SEO.propTypes = {
  isBlogPost: PropTypes.bool,
  postData: PropTypes.shape({
    frontmatter: PropTypes.any,
    excerpt: PropTypes.any,
  }).isRequired,
  postImage: PropTypes.string,
}

SEO.defaultProps = {
  isBlogPost: false,
  postImage: null,
}

export default SEO
