import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import './header.scss'
import Navigation from '../navigation/Navigation'

const Header = () => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            name
            menuLinks {
              text
              link
              children {
                text
                link
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="header__container">
        <Navigation menuLinks={data.site.siteMetadata.menuLinks}/>
      </div>
    )}
  />
)

export default Header
